// icons
import { MenuOutlined } from '@mui/icons-material'
import CampaignIcon from '@mui/icons-material/Campaign'
import { BIGMODULE, MODULE } from 'constants/list-module'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'

const marketing = {
  id: 'marketing',
  icon: <CampaignIcon />,
  title: 'Marketing',
  role: ['*'],
  code: BIGMODULE.Marketing,
  type: 'group',
  children: [
    {
      id: 'noti-setting',
      url: '/noti-setting',
      icon: <MenuOutlined />,
      title: 'Thông báo',
      code: MODULE.NOTIFICATIONS,
      role: ['*'],
    },
    {
      id: 'auto-noti-setting',
      url: '/auto-noti-setting',
      icon: <MenuOutlined />,
      title: 'Quản lí chương trình',
      code: MODULE.AUTO_NOTIFICATIONS,
      role: ['*'],
    },
    {
      id: 'promotion-setting',
      url: '/promotion-setting',
      icon: <MenuOutlined />,
      title: 'Thông tin ưu đãi',
      code: MODULE.PROMOTIONNEWS,
      role: ['*'],
    },
    {
      id: 'voucher',
      url: '/voucher',
      icon: <MenuOutlined />,
      title: 'Cấp code dự phòng',
      code: MODULE.VOUCHER,
      role: ['*'],
    },
    {
      id: 'lucky-spin',
      icon: <SportsEsportsIcon />,
      title: 'Vòng quay may mắn',
      url: '/lucky-spin',
      role: ['*'],
      code: MODULE.LUCKY_SPIN,
      type: 'single',
    },
    {
      id: 'shopping-gift',
      icon: <SportsEsportsIcon />,
      title: 'Mua sắm nhận quà',
      url: '/shopping-gift',
      role: ['*'],
      code: MODULE.SHOPPING_GIFT,
      type: 'single',
    },
    {
      id: 'lucky-game',
      icon: <SportsEsportsIcon />,
      title: 'Lì xì may mắn',
      url: '/lucky-game',
      role: ['*'],
      code: MODULE.LUCKY_GAME,
      type: 'single',
    },

    {
      id: 'promotion-link',
      icon: <MenuOutlined />,
      title: 'CTKM',
      url: '/promotion-link',
      role: ['*'],
      code: MODULE.PROMOTION_LINK,
      type: 'single',
    },
  ],
}

export default marketing
