import { Navigate, useRoutes } from 'react-router-dom'

import ProtectedAuth from 'components/auth/ProtectedAuth'
import useAuth from 'hooks/useAuth'
import MainLayout from 'layout/MainLayout'
import PageNotFound from 'pages/404'
import { checkIsLocal } from 'utils/common'
import AppearanceRoutes from './AppearanceRoutes'
import BannerRoutes from './BannerManage'
import WebSettings from './ConfigRoutes'
import CustomerRoutes from './CustomerRoutes'
import LandingRoutes from './LandingRoutes'
import CkcRoutes from './CkcRouters'
import LoginRoutes from './LoginRoutes'
import OrderManagementRoutes from './OrderManagement'
import PopUpRoutes from './PopUpRoutes'
import PostRoutes from './PostRoutes'
import ProductRoutes from './ProductRoutes'
import RoleManagementRoutes from './RoleManagementRoutes'
import DepotRoutes from './Depot'
import StoreRoutes from './StoreRoutes'
import StaticPageRoutes from './StaticPage'
import ShortLinkRoutes from './ShortLinkRoutes'
import MediasManagementRoutes from './MediasManagementRoutes'
import ShippingFeesManagementRoutes from './ShippingFeesManagementRoutes'
import LuckySpinRoutes from './LuckySpinRoutes'
import LuckyGameRoutes from './LuckyGameRoutes'
import PromotionLinkRoutes from './PromotionLinkRoutes'
import ShoppingGiftRoutes from './ShoppingGiftRoutes'

interface CustomRouteObject {
  path: string
  element: JSX.Element
  code?: string
  children?: any
}

export const GoToForlifeHome = ({ }) => {
  window.location.href = `${process.env.REACT_APP_FORLIFE_HOME}/login?redirect_url=${window.location.href}`
  return <></>
}

const protectRoute = (route: CustomRouteObject) => ({
  ...route,
  element: <ProtectedAuth moduleCode={route?.code}>{route.element}</ProtectedAuth>,
  children: route?.children?.map((childRoute: CustomRouteObject) => protectRoute(childRoute)),
})

const protectedRoutes = (routes: CustomRouteObject[]) => routes.map((route: CustomRouteObject) => protectRoute(route))

export default function ThemeRoutes() {
  const isAuthenticated = useAuth()
  const privateRoutes = [
    PostRoutes,
    ProductRoutes,
    AppearanceRoutes,
    LandingRoutes,
    WebSettings,
    BannerRoutes,
    StoreRoutes,
    CustomerRoutes,
    PopUpRoutes,
    OrderManagementRoutes,
    RoleManagementRoutes,
    DepotRoutes,
    StaticPageRoutes,
    ShortLinkRoutes,
    MediasManagementRoutes,
    ShippingFeesManagementRoutes,
    LuckySpinRoutes,
    CkcRoutes,
    PromotionLinkRoutes,
    ShoppingGiftRoutes,
    LuckyGameRoutes
  ]

  let routes = []

  if (checkIsLocal()) {
    routes = [
      {
        path: '/',
        element: isAuthenticated ? <MainLayout /> : <Navigate to="/login" />,
        children: protectedRoutes(privateRoutes.flat()),
      },
      LoginRoutes,
    ]
  } else {
    routes = [
      {
        path: '/',
        element: isAuthenticated ? <MainLayout /> : <GoToForlifeHome />,
        children: protectedRoutes(privateRoutes.flat()),
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ]
  }

  return useRoutes(routes)
}
