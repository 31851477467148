import ArticleIcon from '@mui/icons-material/Article'
import SellIcon from '@mui/icons-material/Sell'
import { DesktopOutlined } from '@ant-design/icons'
import StoreIcon from '@mui/icons-material/Store'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import WebIcon from '@mui/icons-material/Web'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import GradingIcon from '@mui/icons-material/Grading'
import AddHomeIcon from '@mui/icons-material/AddHome'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'
import CampaignIcon from '@mui/icons-material/Campaign'
import LinkIcon from '@mui/icons-material/Link'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'

export const BIGMODULE = {
  POST: 'post',
  PRODUCT: 'product',
  DISPLAY: 'display',
  Marketing: 'marketing',
  STORE: 'store',
  CUSTOMER: 'customer',
  LANDING_PAGE: 'landing-page',
  CKC_PAGE: 'ckc-page',
  POPUP: 'popup',
  ORDER: 'orders',
  PERMISSION: 'permission',
  DEPOT: 'depots',
  STATIC_PAGE: 'staticpages',
  SHORTLINK: 'short_link',
  MEDIAS_MANAGEMENT: 'medias_management',
  SHIPPING_FEES: 'freeshipconfigs',
}

export const MODULE = {
  POST_POSTS: 'post_posts',
  POST_CATEGORY: 'post_categories',
  POST_TAG: 'post_tags',
  POST_COMMENT: 'post_comments',
  POST_SETTING: 'post_settings',
  PRODUCT: 'catalog_product',
  PRODUCT_CATEGORY: 'product_category',
  PRODUCT_OPTION: 'product_option',
  PRODUCT_ATTRIBUTE_GROUP: 'product_attribute_group',
  PRODUCT_TAG: 'product_tag',
  PRODUCT_COMMENT: 'product_comment',
  MENU: 'menu',
  CONFIGS: 'configs',
  NOTIFICATIONS: 'notifications',
  AUTO_NOTIFICATIONS: 'customer_notifications',
  PROMOTIONNEWS: 'promotion_news',
  PERMISSION: 'permissions',
  STORE: 'stores',
  CUSTOMER: 'customers',
  LANDINGPAGE: 'landing_page',
  CKCPAGE: 'ckc_page',
  POPUP: 'popups',
  ORDER: 'orders',
  DEPOT: 'depots',
  STATIC_PAGE: 'staticpages',
  SHORTLINK: 'short_link',
  EXPORT_XML: 'product_feed',
  MEDIAS_MANAGEMENT: 'medias_management',
  SIZE_GUIDE: 'size_guide',
  SHIPPING_FEES: 'freeshipconfigs',
  LUCKY_SPIN: 'lucky_spin',
  VOUCHER: 'voucher',
  SHOPPING_GIFT: 'shopping_gift',
  LUCKY_GAME: 'lucky_game',

  PROMOTION_LINK: 'promotion_link',
}

export const bigModuleDetails = [
  {
    key: BIGMODULE.POST,
    name: 'Bài viết',
    icon: <ArticleIcon />,
    children: [
      {
        key: MODULE.POST_POSTS,
        name: 'Danh sách bài viết',
      },
      {
        key: MODULE.POST_CATEGORY,
        name: 'Chuyên mục',
      },
      {
        key: MODULE.POST_TAG,
        name: 'Thẻ (Bài viết)',
      },
      {
        key: MODULE.POST_COMMENT,
        name: 'Phản hồi',
      },
      {
        key: MODULE.POST_SETTING,
        name: 'Cài đặt bài viết',
      },
    ],
  },
  {
    key: BIGMODULE.STATIC_PAGE,
    name: 'Quản lí trang tĩnh',
    icon: <WysiwygIcon />,
    children: [
      {
        key: MODULE.STATIC_PAGE,
        name: 'Quản lí trang tĩnh',
      },
    ],
  },
  {
    key: BIGMODULE.PRODUCT,
    name: 'Sản phẩm',
    icon: <SellIcon />,
    children: [
      {
        key: MODULE.PRODUCT,
        name: 'Danh sách sản phẩm',
      },
      {
        key: MODULE.PRODUCT_CATEGORY,
        name: 'Nhóm sản phẩm',
      },
      {
        key: MODULE.PRODUCT_OPTION,
        name: 'Tùy chọn (Sản phẩm)',
      },
      {
        key: MODULE.PRODUCT_ATTRIBUTE_GROUP,
        name: 'Khai báo tùy chọn',
      },
      {
        key: MODULE.PRODUCT_TAG,
        name: 'Thẻ',
      },
      // {
      //   key: MODULE.SIZE_GUIDE,
      //   name: 'Hướng dẫn chọn size',
      // },
      {
        key: MODULE.PRODUCT_COMMENT,
        name: 'Đánh giá - review',
      },
      {
        key: MODULE.EXPORT_XML,
        name: 'Xuất XML',
      },
    ],
  },
  {
    key: BIGMODULE.DISPLAY,
    name: 'Giao diện',
    icon: <DesktopOutlined />,
    children: [
      {
        key: MODULE.MENU,
        name: 'Menu',
      },
      {
        key: MODULE.CONFIGS,
        name: 'Cấu hình',
      },
    ],
  },
  {
    key: BIGMODULE.Marketing,
    name: 'Marketing',
    icon: <CampaignIcon />,
    children: [
      {
        key: MODULE.NOTIFICATIONS,
        name: 'Thông báo',
      },
      {
        key: MODULE.AUTO_NOTIFICATIONS,
        name: 'Quản lí chương trình',
      },
      {
        key: MODULE.PROMOTIONNEWS,
        name: 'Thông tin ưu đãi',
      },
      {
        key: MODULE.LUCKY_SPIN,
        name: 'Vòng quay may mắn',
      },
      {
        key: MODULE.VOUCHER,
        name: 'Cấp code dự phòng',
      },
      {
        key: MODULE.PROMOTION_LINK,
        name: 'Chương trình khuyến mại',
      },
      {
        key: MODULE.SHOPPING_GIFT,
        name: 'Mua sắm nhận quà',
      },
      {
        key: MODULE.LUCKY_GAME,
        name: 'Lì xì may mắn',
      },
    ],
  },
  {
    key: BIGMODULE.STORE,
    name: 'Cửa hàng',
    icon: <StoreIcon />,
    children: [
      {
        key: MODULE.STORE,
        name: 'Thông tin cửa hàng',
      },
    ],
  },
  {
    key: BIGMODULE.CUSTOMER,
    name: 'Khách hàng',
    icon: <SupervisorAccountIcon />,
    children: [
      {
        key: MODULE.CUSTOMER,
        name: 'Thông tin khách hàng',
      },
    ],
  },
  {
    key: BIGMODULE.LANDING_PAGE,
    name: 'Landing page',
    icon: <WebIcon />,
    children: [
      {
        key: MODULE.LANDINGPAGE,
        name: 'Landing page',
      },
    ],
  },
  {
    key: BIGMODULE.CKC_PAGE,
    name: 'Ckc page',
    icon: <WebIcon />,
    children: [
      {
        key: MODULE.CKCPAGE,
        name: 'Ckc page',
      },
    ],
  },

  {
    key: BIGMODULE.POPUP,
    name: 'Pop up',
    icon: <WebIcon />,
    children: [
      {
        key: MODULE.POPUP,
        name: 'Pop up',
      },
    ],
  },
  {
    key: BIGMODULE.ORDER,
    name: 'Quản lí đơn hàng',
    icon: <GradingIcon />,
    children: [
      {
        key: MODULE.ORDER,
        name: 'Quản lí đơn hàng',
      },
    ],
  },
  {
    key: BIGMODULE.DEPOT,
    name: 'Kho lấy tồn',
    icon: <AddHomeIcon />,
    children: [
      {
        key: MODULE.DEPOT,
        name: 'Kho lấy tồn',
      },
    ],
  },
  {
    key: BIGMODULE.SHORTLINK,
    name: 'Short Link',
    icon: <LinkIcon />,
    children: [
      {
        key: MODULE.SHORTLINK,
        name: 'Short Link',
      },
    ],
  },
  {
    key: BIGMODULE.MEDIAS_MANAGEMENT,
    name: 'Quản lí hình ảnh',
    icon: <PermMediaIcon />,
    children: [
      {
        key: MODULE.MEDIAS_MANAGEMENT,
        name: 'Quản lí hình ảnh',
      },
    ],
  },
  {
    key: BIGMODULE.SHIPPING_FEES,
    name: 'Quản lí phí vận chuyển',
    icon: <LocalAtmIcon />,
    children: [
      {
        key: MODULE.SHIPPING_FEES,
        name: 'Quản lí phí vận chuyển',
      },
    ],
  },
  {
    key: BIGMODULE.PERMISSION,
    name: 'Phân quyền',
    icon: <ManageAccountsIcon />,
    children: [
      {
        key: MODULE.PERMISSION,
        name: 'Phân quyền',
      },
    ],
  },
]
